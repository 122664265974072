import React, { useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Auth } from 'aws-amplify'
import { validateFieldUsers } from '../../constants/validator'
import { AlertMessage } from '../../contents/AlertMessage'
import { ButtonSubmit } from '../../contents/Buttons'
import { Input } from '../../contents/Input'
import { traslateMessage } from '../../utils/AwsCodes'

export default function SignInForm(props) {

  const { width, email, setEmail, setEmailSent } = props
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [submit, setSubmit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msg, setMgs] = useState({})
  let msgErrorField = {}

  

  const handleForgot = async event => {
    event.preventDefault()
    setSubmit(true)
    setMgs({})
    setOpenAlert(false)
    setLoading(true)

    if (validateFieldUsers({ email }).is_valid) {
      await Auth.forgotPassword(email)
        .then(data => {setEmailSent(true)})
        .catch(error => {
          setMgs({
            type: 'error',
            message: traslateMessage(error),
          })
          setOpenAlert(true)
          console.log(error)
          setLoading(false)
        });
    } else 
      setLoading(false)
  }

  if (submit) {
    msgErrorField = validateFieldUsers({ email }).msg
  }

  const handleClose = () => {
    setOpenAlert(false)
    setMgs({})
  }
  return (
    <div>
      <AlertMessage msg={msg} openAlert={openAlert} handleClose={handleClose} />
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleForgot}
      >
        <Input
          name="email"
          type="text"
          placeholder="E-mail"
          value={email}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={ setEmail }
        />
        <Typography component="div">
          <Box paddingTop={1} textAlign="left">
            <ButtonSubmit
              title="Recuperar"
              fullWidth={width === 'xs' ? true : false}
              loading={loading}
            />
          </Box>
        </Typography>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    '& > .MuiFormControl-root > label': {
      fontSize: '14px',
    },
  },
}))
