const codes = {
    UserNotConfirmedException: "El usuario no está confirmado.",
    "Password cannot be empty": "El password no puede estar vacío.",
    "Username cannot be empty": "El correo no puede estar vacío.",
    UsernameExistsException: "El correo ya se encuentra registrado.",
    NotAuthorizedException: "Correo o contraseña incorrectos.",
    InvalidPasswordException: "Password inválido: mínimo 6 caracteres, debe tener mayúsculas, minúsculas y números",
    ExpiredCodeException: "Código de verificación inválido.",
    UserNotFoundException: "El usuario ingresado no existe"
}

export const traslateMessage = (errorCode) => {
    const code = ( (typeof errorCode) === 'string' ? errorCode : errorCode.code );
    if ( codes[code] ) {
        return codes[code];
    } else {
        console.log(errorCode);
        console.log(`Error no encontrado ${code}: ${errorCode.message}`);
        return errorCode.message || code;
    }

}
