import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorFields } from './FieldsMessage'

export function Input(props) {
  const {
    label,
    placeholder,
    value,
    setCallBack,
    loading,
    type,
    msgErrorField,
    name,
    trim,
    inputRef,
    variant,
  } = props
  const classes = useStyles()
  return (
    <TextField
      inputRef={inputRef}
      variant={variant ? variant : 'outlined'}
      margin="normal"
      required={false}
      name={name}
      fullWidth
      type={type}
      label={label}
      placeholder={placeholder}
      autoComplete="off"
      size="small"
      value={value}
      disabled={loading}
      error={msgErrorField ? (msgErrorField[name] ? true : false) : false}
      helperText={
        msgErrorField ? (
          msgErrorField[name] ? (
            <ErrorFields msg={msgErrorField[name]} />
          ) : (
            false
          )
        ) : (
          false
        )
      }
      onChange={({ target: { value } }) =>
        setCallBack ? setCallBack(trim ? value.trim() : value) : null
      }
      className={classes.inputField}
    />
  )
}

Input.propTypes = {
  helperText: PropTypes.any,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  setCallBack: PropTypes.func,
}
const useStyles = makeStyles((theme) => ({
  inputField: {
    marginBottom: '0px',
    '& input': {
      border: '#000',
      color: '#000000',
      fontSize: '14px',
      '&::placeholder': {
        fontWeight: 300,
        fontFamily: 'sans-serif',
        opacity: 0.5,
      },
      '&:label': {
        fontSize: '14px',
        fontFamily: 'sans-serif',
        opacity: 1,
      },
    },
    '& > .MuiFormLabel-root': {
      fontSize: '1.3em',
    },
  },
}))
