import React, { useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from 'gatsby'
import { Auth } from 'aws-amplify'
import { validateFieldUsers } from '../../constants/validator'
import { AlertMessage } from '../../contents/AlertMessage'
import { ButtonSubmit } from '../../contents/Buttons'
import { Input } from '../../contents/Input'
import { traslateMessage } from '../../utils/AwsCodes'

export default function SignInForm(props) {
  const { width, email } = props
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [code, setCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [submit, setSubmit] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [msg, setMgs] = useState({})
  // const [msgErrorField, setMsgErrorField] = useState({})
  let msgErrorField = {}

  const handleForgot = async event => {
    event.preventDefault()
    setSubmit(true)
    setMgs({})
    setOpenAlert(false)
    setLoading(true)

    // Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(data => {
        navigate('/signin/', {
          replace: true,
          state: {
            msg: {
              message: 'Tu cuenta se verificar exitosamente',
              type: 'success',
            },
          },
        })
      }).catch(error => {
        setMgs({
          type: 'error',
          message: traslateMessage(error),
        })
        setOpenAlert(true)
        console.log(error)
        setLoading(false)
      });

  }

  if (submit) {
    msgErrorField = validateFieldUsers({ email }).msg
  }

  const handleClose = () => {
    setOpenAlert(false)
    setMgs({})
  }
  return (
    <div>
      <AlertMessage msg={msg} openAlert={openAlert} handleClose={handleClose} />
      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={handleForgot}
      >
        <Input
          name="email"
          type="text"
          placeholder="E-mail"
          readOnly={true}
          value={email}
          loading={true}
        />
        <Input
          name="code"
          placeholder="Código de verificación"
          value={code}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setCode}
        />
        <Input
          name="password"
          type="password"
          placeholder="Nueva Contraseña"
          value={newPassword}
          loading={loading}
          msgErrorField={msgErrorField}
          setCallBack={setNewPassword}
        />
        <Typography component="div">
          <Box paddingTop={1} textAlign="left">
            <ButtonSubmit
              title="Confirmar"
              fullWidth={width === 'xs' ? true : false}
              loading={loading}
            />
          </Box>
        </Typography>
      </form>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%',
    '& > .MuiFormControl-root > label': {
      fontSize: '14px',
    },
  },
  active: {
    color: '#60D957',
    '&:active': {
      color: '#60D957',
    },
    '& > .MuiTypography-body2': {
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '22px',
    },
    buttonSubmit: {
      marginTop: '3em',
    },
  },
  buttonForgot: {
    margin: theme.spacing(3, 0, 2),
    background: 'linear-gradient(#60D957, #60D957)',
    border: 0,
    borderRadius: 3,
    color: '#fffffffa',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '2px',
    textAlign: 'center',
    boxShadow: 'none',
    height: 40,
    padding: '0 30px',
    '&:hover': {
      boxShadow: 'none',
      boder: 'none',
    },
    '&:disabled': {
      background: '#4B9845',
      color: '#ffffffad',
    },
  },
}))
