import { re_email, re_siteWeb, re_code } from './regExp'

export function validateFieldUsers(props) {
  const { email, password, webSite, code } = props
  let msg = {},
    is_valid = true

  if (email === '') {
    is_valid = false
    msg.email = 'Ingrese e-mail'
  } else if (email && !re_email.test(email)) {
    is_valid = false
    msg.email = 'E-mail invalido'
  }

  if (password === '') {
    is_valid = false
    msg.password = 'Ingrese contraseña'
  }

  if (webSite === '') {
    is_valid = false
    msg.webSite = 'Ingrese sitio web'
  } else if (webSite && !re_siteWeb.test(webSite)) {
    is_valid = false
    msg.webSite = 'Sitio web invalido'
  }
  if (webSite === '') {
    is_valid = false
    msg.webSite = 'Ingrese sitio web'
  } else if (webSite && !re_siteWeb.test(webSite)) {
    is_valid = false
    msg.webSite = 'Sitio web invalido'
  }

  if (code === '') {
    is_valid = false
    msg.code = 'Código requerido'
  } else if (code && !re_code.test(code)) {
    is_valid = false
    msg.code = 'Número invalido'
  }

  return { is_valid, msg }
}

export function validateFieldLicenses(props) {
  const { frEmail, web, licenseType, timestampActiveUntil, back_url } = props
  let msg = {},
    is_valid = true

  if (frEmail === '') {
    is_valid = false
    msg.frEmail = 'Ingrese e-mail'
  } else if (frEmail && !re_email.test(frEmail)) {
    is_valid = false
    msg.frEmail = 'E-mail invalido'
  }

  if (web === '') {
    is_valid = false
    msg.web = 'Ingrese sitio web'
  } else if (web && !re_siteWeb.test(web)) {
    is_valid = false
    msg.web = 'Sitio web invalido, ejemplo de sitio válido: https://miweb.com http://www.miweb.com'
  }
  
  if (back_url && !re_siteWeb.test(back_url)) {
    is_valid = false
    msg.back_url = 'Sitio web invalido, ejemplo de sitio válido: https://miweb.com http://www.miweb.com'
  }

  if (licenseType === '') {
    is_valid = false
    msg.licenseType = 'Seleccione tipo de licencia'
  }

  if (timestampActiveUntil === '') {
    is_valid = false
    msg.timestampActiveUntil = 'Ingrese fecha vencimiento'
  }

  return { is_valid, msg }
}
