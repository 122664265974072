import React from 'react'
import {
  AppBar,
  Toolbar,
  Button,
  withWidth,
  useScrollTrigger,
  Slide,
  Typography,
  IconButton,
  Container,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from 'gatsby'

import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'

function HideOffScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

function HeaderMenuNotScroll(props) {
  let menuLeftOptions = null,
    menuRightOptions = null
  const classes = useStyles()
  const { menuLeft, menuRight, path } = props

  const data = useStaticQuery(graphql`
    query imagesLogoLanding {
      imagesLogoLanding: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 110) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const handlePage = url => {
    navigate(url, {
      replace: true,
    })
  }

  if (menuLeft) {
    menuLeftOptions = (
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => handlePage(path)}
        className={classes.iconBackButton}
      >
        <ArrowBackIcon />
      </IconButton>
    )
  }
  if (menuRight === null || menuRight) {
    menuRightOptions = menuRight
  } else {
    menuRightOptions = <div className={classes.itmes}></div>
  }
  return (
    <HideOffScroll {...props}>
      <AppBar className={classes.transparent}>
        <Container maxWidth="xl" className={classes.container}>
          <Toolbar>
            {menuLeftOptions}
            <Button
              onClick={() => handlePage('/')}
              className={classes.imageButton}
            >
              <Img fixed={data.imagesLogoLanding.childImageSharp.fixed} />
            </Button>

            <Typography variant="h6" className={classes.title}></Typography>
            {menuRightOptions}
          </Toolbar>
        </Container>
      </AppBar>
    </HideOffScroll>
  )
}

export default withWidth()(HeaderMenuNotScroll)

const useStyles = makeStyles(theme => ({
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none',
    color: '#FFFFFF',
  },
  imageButton: {
    paddingLeft: '0em',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  title: {
    flexGrow: 1,
  },
  itmes: {
    '& > *': {
      marginLeft: theme.spacing(2),
      height: '40px',
      width: '117px',
      borderRadius: '5px',
      backgroundColor: '#60D957',
    },
  },
  iconBackButton: {
    color: '#000',
  },
  container: {
    paddingLeft: '0em',
    paddingRight: '0em',
    [theme.breakpoints.only('xl')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
}))
