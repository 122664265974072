import React, { useEffect, useState } from 'react'
import { withWidth } from '@material-ui/core'
import Header from '../../contents/Header'
import InitUserPage from '../../contents/InitUserPage'
import ForgotPasswordForm from './ForgotPasswordForm'
import ConfirmForgotPasswordForm from './ConfirmForgotPasswordForm'
import { isLoggedIn } from '../../utils/auth'
import { navigate } from 'gatsby'

function SignInPage(props) {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState('')

  useEffect(() => {
    ;(async () => {
      if (await isLoggedIn()) {
        navigate('/dashboard/')
      }
    })()
  }, [])

  return (
    <div>
      <Header {...props} menuRight={null} />
      <InitUserPage {...props} title="Recuperar Contraseña">
        {emailSent ? (
          <ConfirmForgotPasswordForm email={email} />
        ) : (
          <ForgotPasswordForm
            email={email}
            setEmail={setEmail}
            setEmailSent={setEmailSent}
          />
        )}
      </InitUserPage>
    </div>
  )
}

export default withWidth()(SignInPage)
