import React, { cloneElement } from 'react'
import { Typography, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export default function InitUserPage(props) {
  const { children, title } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.content}>
        <Box
          textAlign="left"
          fontWeight="fontWeightBold"
          fontSize={34}
          lineHeight={1.4}
          paddingBottom={1.5}
        >
          {title}
        </Box>
        <Box maxWidth={500}>
          {children ? cloneElement(children, { ...props }) : null}
        </Box>
      </Typography>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8em 1em',
    [theme.breakpoints.only('xl')]: {
      padding: '12em 1em',
    },
    [theme.breakpoints.only('lg')]: {
      padding: '12em 1em',
    },
    [theme.breakpoints.only('md')]: {
      padding: '12em 1em',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '12em 1em',
    },
  },
  content: {
    height: '250px',
    width: '450px',
  },
}))
